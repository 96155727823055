// this is kept just for color refence
$colors-hex-ref: (
  c-blue:#04003f,
  c-orange:#F58220,
  c-white:#FDFCFF,
  c-black:#181818,
  c-blue-light:#CDCCD9,
  //system colors
  c-pure-white: #ffffff,
  c-pure-black: #000000,
  c-info: #0163f7,
  c-warning: #fe8800,
  c-error: #D71111,
  c-success: #09c270,
  c-shimmer-light: #dedede,
  c-shimmer-dark: #a5a1a1,
  #D9d9d9 //event ids
  //world-championship
  c-w-champ-primary:#4EABE9,
  c-w-champ-secondary:#00003B,
  // continental-championship
  c-c-champ-primary:#B0335D,
  c-c-champ-secondary:#420C2A,
  // world-cup
  c-w-cup-primary: #85C25A,
  c-w-cup-secondary: #1D3530,
  //ranking-series
  c-rank-series-primary:#787D7B,
  c-rank-series-secondary:#2C353A,
  //beach Championship
  c-beach-champ-primary:#009EB4,
  c-beach-champ-secondary:#007F9C,
  //corporate/institutional/international-tournament
  c-institutional-primary:#68668C,
  c-institutional-secondary:#04003F,
  // Olympic Qualifier
  c-olympic-qualifier-primary: #fafafb,
  c-olympic-qualifier-secondary: #dbdbdb,
  // Variable added after VQA
  c-grey:#9B99B2,
  c-light-grey-1:#E6E5EC,
  c-light-grey: #D9D9D9,
  c-light-grey-2: #f8f7f7,
  c-blue-1: #68668c,
  c-blue-2: #363365,
  c-blue-3: #030129,
  c-ghost-white: #fafafb,
  c-bright-gray: #eeedf2,
  c-cultured: #f4f4f7,
  c-dark-blue: #000088,
  c-dark-liver: #4e4e4e,
  c-cetacean-blue: #060240,
  c-white-2: #f0f0f0,
  c-world-cup-card-1: #328C56,
  c-world-cup-card-2: #0E2919,
  c-continental-card-1: #9D2D56,
  c-continental-card-2: #4B1030,
  c-beach-card-1: #296290,
  c-beach-card-2: #48A1B5
);
$colors: (
  c-blue:244 100% 12%,
  c-orange:28 91% 54%,
  c-white:260 100% 99%,
  c-black:0 0% 9%,
  c-blue-light:245 15% 83%,
  //system colors
  c-pure-white: 0 0% 100%,
  c-pure-black: 0 0% 0%,
  c-info: 216 99% 49%,
  c-warning: 32 100% 50%,
  c-error: 0 85% 45%,
  c-success: 153 91% 40%,
  c-shimmer-light: 0 0% 87%,
  c-shimmer-dark: 0 2% 64%,
  //event ids
  c-w-champ-primary:204 78% 61%,
  c-w-champ-secondary:240 100% 12%,
  c-c-champ-primary:340 55% 45%,
  c-c-champ-secondary:327 69% 15%,
  c-w-cup-primary: 95 46% 56%,
  c-w-cup-secondary: 168 29% 16%,
  c-rank-series-primary:156 2% 48%,
  c-rank-series-secondary:201 14% 20%,
  c-beach-champ-primary:187 100% 35%,
  c-beach-champ-secondary:191 100% 31%,
  c-institutional-primary:243 16% 47%,
  c-institutional-secondary:244 100% 12%,
  c-olympic-qualifier-primary: 240 11% 98%,
  c-olympic-qualifier-secondary: 0 0% 86%,
  // Variable added after VQA
  c-grey: 245 14% 65%,
  c-light-grey: 0 0% 85%,
  c-blue-1: 243 16% 47%,
  c-blue-2: 244 33% 30%,
  c-light-grey-1: 249 16% 91%,
  c-light-grey-2: 0 7% 97%,
  c-blue-3: 243 95% 8%,
  c-ghost-white: 240 11% 98%,
  c-bright-gray: 252 16% 94%,
  c-cultured: 240 16% 96%,
  c-dark-blue: 240 100% 27%,
  c-dark-liver: 0 0% 31%,
  c-cetacean-blue: 244 94% 13%,
  c-white-2: 0 0% 94%,
  c-world-cup-card-1: 144 47% 37%,
  c-world-cup-card-2: 144 49% 11%,
  c-continental-card-1: 338 55% 40%,
  c-continental-card-2: 327 65% 18%,
  c-beach-card-1: 207 56% 36%,
  c-beach-card-2: 191 43% 50%
);
$gradient-colors: (
  //gradient
  c-white-stroke:(var(--c-pure-white) 70%, var(--c-pure-white) 0%, var(--c-pure-white) 70%),
);
$gradient-direction: (
  -d-top: to top,
  -d-bottom: to bottom,
  -d-left: to left,
  -d-right: to right,
  -d-0: 0deg,
  -d-45: 45deg,
  -d-90: 90deg,
  -d-180: 180deg
);
$aspect-ratio: (
  4 3,
  3 4,
  16 9,
  1 1,
  2 3,
  9 16,
  21 9,
  18 9,
  18 6,
  6 4
);
$flex-width: (
  20,
  25,
  30,
  33,
  40,
  50,
  60,
  70,
  80,
  100
);
$font-detail: (
  OpenSans-Regular: 400,
  OpenSans-Medium: 500,
  OpenSans-SemiBold: 600,
  OpenSans-Bold: 700,
  Fedra-SansConds-Bold: 700,
);
$directions: (
  t: -top,
  r: -right,
  b: -bottom,
  l: -left,
  x: -inline,
  y: -block,
);
$spacing: (
  full: var(--full-space),
  half: var(--half-space),
  full-neg: var(--full-space-negative),
  half-neg: var(--half-space-negative),
  quarter: var(--quarter-space),
  quater-neg: var(--quarter-space-negative),
  one-n-half:var(--one-n-half-space),
  two-space:var(--two-space),
  three-space:var(--three-space),
  zero: 0,
  auto: auto,
);
$flex-spacing: (
  -sa: space-around,
  -sb: space-between,
  -se: space-evenly,
  -fe: flex-end,
  -fs: flex-start,
  -c: center,
  -s: stretch,
  -u: unset,
  -n: null
);
$media-query: (
  col: 300px,
  col-md: 768px,
  col-lg: 1200px,
  col-xl: 1600px,
);
$social: (
  facebook: \e854,
  twitter: \e861,
  whatsapp: \e855,
  copylink: \e853,
  instagram: \e85e,
  tiktok: \e85f,
  close: \e805,
  website: \e820,
  youtube: \e864,
  linkedin: \e862
);
//curently in R&D dont use
$icons: (
  thubm-up: '\e816',
  share: '\e816',
);
$social: (
  "tiktok": "\e85f",
  "twitter": "\e861",
  "instagram": "\e85e",
  "share": "\e816",
  "favorite": "\e948",
  "compare": "\e824",
  "copylink": "\e853",
  "facebook": "\e854",
  "whatsapp": "\e855",
  "youtube": "\e864",
  "linkedin": "\e862",
  "website": "\E8BD"
);
$events: (
  world-championships :w-champ,
  continental-championships: c-champ,
  world-cup:w-cup,
  ranking-series :rank-series,
  beach-wrestling-world-series:beach-champ,
  international-tournament:institutional,
  olympic-qualifying-tournament: olympic-qualifier,
  olympic-games: olympic-qualifier,
  corporate: institutional,
  cup: institutional,
  games: institutional,
  championships: institutional
);
$ranks: (
  gold-rank:gold-medal,
  silver-rank:silver-medal,
  bronze-rank:bronze-medal)