@use "../config/" as *;

.social-icon {
    &::before {
        font-family: $font-icon;
        font-size: 1.6rem;
    }
}

@each $icon,
$value in $social {
    .icon-#{$icon}{
        &::before {
            content: $value;
        }
    }
}

.social {
    &-share {
        width: 3rem;
        height: 3rem;

        &.active {
            .social-share-wrap {
                @extend %flex;
            }
        }

        .share {
            &-icon {
                width: 3rem;
                height: 3rem;
                aspect-ratio: 1/1;
            }

            &-label {
                @extend %d-none;
            }
        }

        .icon-b-share {
            &::before {
                content: '\e816';
            }
        }

        .icon-b-close {
            &::before {
                content: '\e804';
                @extend %c-white-10;
            }
        }

        &-wrap {
            @extend %d-none;
            @extend %position-b-r;
        }
    }

    &-items {
        @extend %flex;
        // @extend %c-orange-bg-10;
    }

    &-icon {
        width: 3.5rem;
        height: 3.5rem;
        aspect-ratio: 1/1;
        @extend %flex-c-c;
        &::before {
            width: 3rem;
            height: 3rem;
            font: 1.8rem $font-icon;
            @extend %flex-c-c;
            @extend %c-white-10;
        }
        &:hover {
            @extend %c-white-bg-10;
            &:first-child {
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
            &::before {
                @extend %c-orange-10;
            }
        }
    }

    &-wrap {
        @extend %flex;
        @extend %c-orange-bg-10;
        @extend %half-radius;
        .close {
            width: 3.5rem;
            height: 3.5rem;
            &:hover {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
                @extend %c-white-bg-10;
                .icon-b-close {
                    &::before {
                        @extend %c-orange-10;
                    }
                }
            }
        }
    }
}