@use "../config" as *;
.breadcrumb-wrap {
  display: none;
}
.home {
  .waf-breadcrumb {
    @extend %d-none;
  }
}
.breadcrumb-wrap {
  border-bottom: .1rem solid var(--c-grey);
  z-index: var(--zindex-inside-breadcrumb);
  @include position(var(--header-height), null, null, 0, fixed);
  @extend %w-100;
  @extend %c-white-bg-10;
}
.waf-breadcrumb {
  padding: 0;
  margin-block: 0;
  .layout-wrapper {
    @extend %p-x-full;
  }
  nav {
    max-width: calc(100% - 3.6rem - var(--half-space));
    width: fit-content;
    overflow-x: auto;
    direction: rtl;
  }
  .breadcrumb {
    height: var(--inside-page-breadcrumb-height);
    gap: 3rem;
    list-style-type: none;
    direction: ltr;
    white-space: nowrap;
    @extend %flex-fe-c;
    @extend %text-left;
    &-item {
      &:not(:last-child) {
        @extend %relative;
        &::after {
          content: "";
          width: .15rem;
          transform: rotate(19.79deg);
          @include position(0, -1.5rem);
          @extend %h-100;
          @extend %c-orange-bg-10;
        }
      }
      &:last-child span {
        @extend %c-blue-10;
      }
      span {
        line-height: 1.5;
        @extend %font-16;
        @extend %c-grey-10;
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .breadcrumb-wrap {
    position: unset;
    border-bottom: 0;
  }
  .waf-breadcrumb {
    .layout-wrapper {
      padding-inline: 0;
    }
    nav {
      max-width: 100%;
      overflow-x: unset;
    }
    .breadcrumb {
      padding-top: 4.5rem;
      align-items: unset;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem 6rem;
      justify-content: unset;
      &-item {
        height: fit-content;
        &:not(:last-child)::after {
          right: -3rem;
        }
      }
    }
  }
}
@media (min-width: $large-desktop-min-width) {
  .waf-breadcrumb .layout-wrapper {
    max-width: calc(100% - 2 * var(--container-white-space));
  }
}