@use "../config/" as *;

.client{
    &-section {
        @extend %p-x-half;
    }
    &-list{
        overflow-x: auto;
        gap:var(--three-space);
        padding-top: calc(4*var(--full-space));
        padding-bottom: calc(3*var(--full-space));
        // @extend %p-y-half;
       @extend %flex-n-c;
    }
    &-item{
        max-width: 7rem;
        &:hover{
            img{
                filter: brightness(0.5);
            }
        }
        &.client-item-lg {
            max-width: 10rem;
        }
    }
    &-logo{
       @extend %w-100;
        .img{
            @extend %w-100;
        }
    }
}
.watch-center,.past-match, .subscription-page {
    .client-section {
        @extend %c-blue-bg-10;
    }
}
.past-match {
    .client-section {
        @extend %c-blue-3-bg-10;
    }
}
.inside-uww-page .client-section {
    display: none;
}


@media (min-width:$tablet-min-width) {
    .client{
        &-section {
            padding-inline: 0;
        }
        &-list {
            gap: var(--full-space);
            justify-content: space-between;
            // margin-block: calc(var(--two-space) * 2);
        }
        &-item{
            max-width: 10rem;
            &.client-item-lg {
                max-width: 15rem;
            }
        }
        
    }
}
@media screen and (min-width: $large-desktop-min-width) {
    .client-list {
        gap: calc(3*var(--two-space));
        justify-content: center;
    }
}