@use '../config/' as *;
.no-data-wrap{
    @extend %flex-c-c;
    @extend %p-full;
    .title{
        padding: var(--half-space) var(--full-space);
        box-shadow: 0 var(--quarter-space) var(--full-space) hsl(var(--hsl-c-black)/0.1);
        border-radius: var(--half-radius);
        width: 45rem;
        @include border(1,c-white,2);
        @extend %text-center;
        @extend %font-14-pr;
        @extend %c-white-10;
        @extend %w-100;
        @extend %c-blue-bg-10;
    }
    max-width: 47rem;
    @include flex-config(null, column);
    @include border(1, c-white, 2);
    @extend %m-y-two-space;
    @extend %m-x-auto;
    @extend %c-white-bg-1;
    @extend %full-radius;
    .title {
        width: 100%;
        // background-color: transparent;
        border: 0;
        @extend %font-20-pr;
    }
    .no-data-option {
        border-top: 0.1rem solid var(--c-white);
        @include border(1, c-white, 2, top);
        @extend %text-center;
        @extend %w-100;
        @extend %m-t-full;
        @extend %p-t-full;
    }
    .search-txt {
        @extend %font-16-pr;
        @extend %c-white-10;
        @extend %m-b-half;
    }
    .txt1 {
        font-family: $font-pb;
    }
    a {
        text-decoration: underline;
    }
}