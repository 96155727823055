@use "../config" as *;

.subscribe-common-banner {
    &.waf-component {
      padding: 0;
      margin: 0 var(--half-space-negative);
    }
    .waf-banner {
      height: 65rem;
      isolation: isolate;
      @extend %relative;
      &::after {
        content: "";
        z-index: var(--zindex-pattern);
        background: url('/static-assets/images/cssimages/paywall-subscribe-bg-mob.png?v=#{$image-version}') center / cover no-repeat;
        pointer-events: none;
        @include position-combo("bl");
        @extend %w-100;
        @extend %h-100;
      }
      .layout-wrapper {
        @extend %h-100;
      }
      .thumbnail {
        padding-top: 12rem;
      }
      .img-box {
        @extend %relative;
        &::before {
          content: "";
          background: linear-gradient(180deg, hsl(var(--hsl-c-blue) / 0) 0%, hsl(var(--hsl-c-blue) / 0.7) 100%);
          pointer-events: none;
          @include position-combo(inset);
        }
      }
      .content-wrapper {
        padding: 0 var(--full-space) 4rem;
        background: linear-gradient(359.95deg, var(--c-blue) 64.46%, rgba(4, 0, 63, 0) 78.56%);
        @include position-combo("bl");
        @extend %w-100;
      }
      .title {
        line-height: 1.22;
        @extend %m-b-one-n-half;
        @extend %c-pure-white-10;
        @extend %font-36-sb;
        @extend %uppercase;
      }
      .desc {
        @extend %m-b-full;
        @extend %c-pure-white-10;
        @extend %font-16-pr;
      }
      .btn-site {
        height: 4rem;
        @extend %w-100;
        @extend %rounded-radius;
        @extend %flex-c-c;
        @extend %c-white-bg-10;
        &:hover {
            @extend %c-orange-bg-10;
            .btn-text {
                @extend %c-white-10;
            }
        }
      }
      .btn-text {
        @extend %c-blue-10;
        @extend %font-16-pr;
      }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .subscribe-common-banner {
        .layout-wrapper {
          max-width: unset;
          margin: 0;
        }
        .waf-banner {
            @include flex-config(flex, null, flex-end);
            &::after {
                height: 100%;
                background-image: url('/static-assets/images/cssimages/paywall-subscribe-bg-desk.png?v=#{$image-version}');
            }
            .thumbnail {
                padding-top: 0;
                img {
                    width: auto;
                    height: 100%;
                    opacity: .9;
                }
            }
            .img-box {
                height: 100%;
                text-align: right;
                &::before {
                    width: 15%;
                    height: 100%;
                    inset: unset;
                    top: 0;
                    right: 0;
                    background: var(--c-blue);
                    filter: blur(.1rem);
                    opacity: .7;
                }
            }
            .content-wrapper {
                width: 45%;
                padding: 0;
                top: 20%;
                bottom: unset;
                left: var(--container-white-space);
                background: none;
            }
            .title {
                font-size: 6.4rem;
                @include truncate-vertical(3, 64, 78);
            }
            .desc {
                width: 70%;
                margin-bottom: var(--three-space);
                font-size: 1.8rem;
            }
            .btn-site {
                display: inline-flex;
                width: auto;
                padding-inline: var(--full-space);
            }
        }
    }
}