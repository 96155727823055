@use '../config/' as *;
.footer {
    &-top-wrapper {
        padding: calc(4 * var(--full-space)) var(--three-fourth-space);
        @extend %c-blue-bg-10;
        @extend %c-white-10;
    }
    &-contact {
        &-nav {
            @extend %font-18-psb;
            // @extend %m-b-full;
        }
        &-nav-link {
            .nav-anchor {
                @extend %font-14-pr;
                @extend %c-white-6;
            }
        }
        &-section {
            margin-bottom: calc(4 * var(--full-space));
        }
    }
    &-title {
        @extend %font-18-psb;
    }
    &-middle-wrapper {
        padding: 0 var(--three-fourth-space) var(--full-space);
        @extend %c-blue-bg-10;
        @extend %c-white-10;
    }
    &-nav-link {
        @extend %font-16-pr;
        // @extend %uppercase;
    }
    &-nav-list {
        .nav-anchor {
            @extend %flex;
            @extend %font-14-pr;
            @extend %c-white-6;
            @extend %m-y-full;
            &:hover {
                // @include translate(var(--full-space), 0);
                @include transition(0.5s ease);
                @extend %c-white-10;
            }
        }
        .btn-text {
            @extend %font-16-pr;
            @extend %c-white-10;
            // @extend %uppercase;
        }
    }
    &-nav {
        @include grid(repeat(2, 1fr));
        &-title {
            @extend %uppercase;
            @extend %font-16-psb;
        }
    }
    &-bottom-wrapper {
        position: relative;
        // height: 12.8rem;
        flex-direction: column-reverse;
        @extend %c-blue-bg-10;
        @extend %c-white-4;
        @extend %flex-c-c;
        @extend %p-full;
        @extend %gap-full;
        &::before {
            content: '';
            width: 90%;
            height: 0.2rem;
            @include position(0);
            @extend %position-h-center;
            @extend %c-white-bg-2;
        }
        p,
        .copyright-text {
            @extend %font-12-psb;
        }
    }
    &-social {
        .footer {
            &-anchor-text {
                @extend %font-zero;
            }
            &-link-list {
                flex-wrap: wrap;
                padding-block: calc(3 * var(--half-space));
                @extend %flex;
                @extend %gap-full;
            }
            @each $icon,
            $value in $social {
                &-link-#{$icon} {
                    a {
                        width: 3.8rem;
                        height: 3.8rem;
                        @extend %c-blue-2-bg-10;
                        @extend %circle-radius;
                        @extend %flex-c-c;
                        &::before {
                            content: $value;
                            @include font(20, null, $font-icon);
                            @extend %c-white-10;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .footer {
        &-top-wrapper {
            padding: calc(5 * var(--full-space)) var(--container-white-space) 0;
        }
        &-middle-wrapper {
            padding: calc(5 * var(--full-space));
        }
        &-nav {
            @include grid(repeat(3, 1fr));
        }
        &-bottom-wrapper {
            padding-inline: calc(5 * var(--full-space));
            flex-direction: row;
            justify-content: space-between;
            // @include flex-config(null, null, flex-start);
        }
        &-title {
            @include font(32, null, $font-pr);
        }
    }
}
@media (min-width:$desktop-min-width) {
    .footer {
        &-nav {
            @include grid(repeat(7, 1fr));
        }
        &-contact {
            &-heading {
                @include font(32, 40, $font-pr);
            }
            &-nav-link {
                .nav-anchor {
                    @include font(16);
                }
            }
            &-title {
                @include font(32);
            }
        }
    }
}