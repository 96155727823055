@use '../config/' as *;
.pagination{
    @extend %flex-c-c;
    &-wrap{
        @extend %d-block;
        @extend %m-auto;
    }
    .nav{
        &-first{
            @extend %d-none;
        }
        &-last{
            @extend %d-none;
        }
        &-arrow{
            cursor: pointer;
            @extend %flex-c-c;
            span{
                @extend %font-zero;
            }
            &:after{
                @extend %c-white-10;
                @include icon('\e800');
                @include font(25,null,$font-icon);
            }
            &.nav-right{
                @include transform(rotate(180deg));
            }
        }
    }
    &-number{
        overflow: hidden;
        @extend %flex-c-c;
        a{
            flex-shrink: 0;
            @extend %c-grey-10;
            @extend %m-x-full;
            @extend %font-16-pr;
            @extend %flex;

            &.active{
                width: 3.2rem;
                height: 3.2rem;
                font-weight: 700;
                @extend %ratio-1-1;
                @extend %c-blue-2-bg-10;
                @extend %c-white-10;
                @extend %circle-radius;
                @extend %flex-c-c;
            }
        }
    }
}


@media screen and (min-width:$tablet-min-width) {
    .pagination{
        &-number{
            a{
                margin-inline: var(--one-n-half-space);
            }
        }
        &-wrap{
            width: 100%;
        }
    }
    
}
