@use "../config/"as *;


//-toggle
.filter-hide-data {
    @include flex-config(flex, row-reverse, space-between, center);

    .toggle {
        &-input {
            position: absolute;
            opacity: 0;
            width: 5rem;
            height: 3rem;
            cursor: pointer;
            z-index: var(--zindex-input);

            &:checked {
                +.toggle-body {
                    @extend %c-orange-bg-10;

                    &:before {
                        @include transition(0.2s linear);
                        @include transform(translateX(2.1rem) translateY(-50%));
                    }
                }
            }
        }

        &-body {
            position: relative;
            width: 5rem;
            height: 2.8rem;
            cursor: pointer;
            @include transition(background-color .4s linear);
            @include border-radius(10vmax);
            @extend %d-block;
            @extend %c-white-bg-5; //need to check on white background

            &:before {
                content: '';
                width: 2.2rem;
                height: 2.2rem;
                @extend %d-block;
                @extend %c-white-bg-10;
                @extend %ratio-1-1;
                @extend %circle-radius;
                @include transition(0.2s linear);
                @include position-combo(y-center, null, 0.4rem);
            }
        }

    }
}


//pwd-toggle(eye show/hide things)
.btn-pwd-toggle,
.btn-code-toggle {
    width: 4rem;
    height: 4rem;
    @include position-combo(br);
    @extend %ratio-1-1;

    &[aria-expanded="true"] {
        .password-sign {
            &::after {
                @include icon('\e818');
                @include font(22, null, $font-icon);
            }
        }
    }

    .password-sign,
    .code-sign {
        &:after {
            @include icon('\e81e');
            @include font(22, null, $font-icon);
            @extend %c-black-10;
        }
    }

}


input,
select {
    @extend %p-y-half;

    &::placeholder {
        @extend %c-white-6;
    }
}