@use "../config" as *;

.waf-error {
    margin: 0;
    padding-inline: var(--half-space);
    padding-top: calc(2*var(--three-space));
    .error-section {
        @extend %text-center;
    }
    .image {
        width: 10rem;
        height: auto;
    }
    .title {
        line-height: 3.4rem;
        @extend %m-b-full;
        @extend %uppercase;
        @extend %c-blue-10;
        @extend %font-30-pb;
    }
    .text {
        @extend %m-b-full;
        @extend %c-black-10;
        @extend %font-14-pr;
    }
    .error-logo {
        @extend %m-b-full;
    }
    .text-link {
        @extend %font-14-pb;
        text-decoration: underline;
    }
}
@media screen and (min-width:$tablet-min-width) {
    .waf-error {
        padding-top: calc(3*var(--three-space));
        .title {
            font-size: 3.6rem;
            margin-bottom: var(--two-space);
        }
        .text {
            font-size: 1.8rem;
            margin-bottom: var(--half-space);
        }
        .text-link {
            font-size: 1.8rem;
        }
    }
}