@use '../config/' as *;
//tabs
.tabs {
    overflow: auto;
    @extend %w-100;
    @extend %flex;
    @extend %m-y-full;
    .tab {
        &-name {
            width: 100%;
            min-width: max-content;
            cursor: pointer;
            opacity: .6;
            @include border(2, c-blue, 2, bottom);
            @extend %p-half;
            @extend %c-blue-10;

            &.active {
                opacity: 1;
                @include border(2, c-blue, 9, bottom);
                .tab-text {
                    color: inherit;
                    font-family: $font-psb;
                }
            }
        }

        &-text {
            @extend %c-blue-10;
            @extend %font-18-pr;
        }
    }
}
.tabs.secondary{
    @include flex-config(null,null,flex-start);

    .tab{
        &-name{
            width: max-content;
            padding: 0.5rem 1rem;
            @include border(1,c-blue);
            @extend %rounded-radius;
            &.active{
                @extend %c-blue-bg-10;
                @extend %c-white-10;
                .tab-text{
                    font-family: $font-pr
                }
            }
        }
        &-text{
            @extend %font-12-pr;
        }
    }
}
.secondary-site-nav {
    border-bottom: .1rem solid hsl(var(--hsl-c-blue)/0.2);
    // @extend %m-x-half-neg;
    .navigation {
        &-link-list {
            overflow-x: auto;
            @extend %flex;
        }
        &-link {
            opacity: .6;
            min-width: max-content;
            @extend %flex-c-c;
            @extend %w-100;
            @extend %p-full;
            &.active {
                opacity: 1;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: .2rem;
                    width: 100%;
                    @extend %c-orange-bg-10;
                }
                // border-bottom: .2rem solid hsl(var(--hsl-c-orange));
                .nav-text,.btn-text {
                    font-family: $font-pb;
                }
            }
        }
    }
    .nav-text,.btn-text {
        @extend %capitalize;
        @extend %c-blue-10;
        @extend %font-14-pr;
    }
}
// .athlete-nav {
//     width: 100%;
//     position: absolute;
//     top: 20rem;
//     z-index: 1;
// }
.secondary-nav-sticky {
	.athlete-nav {
        position: sticky;
        top: 0;
	}
}
@media (min-width:$tablet-min-width) {
    .secondary-site-nav {
        .navigation {
            &-link-list {
                max-width: var(--container-max-width);
                margin-inline: auto;
            }
            &-link {
                padding-block: var(--full-space);
            }
        }
        .nav-text,.btn-text {
            @include font(18,$font-psb);
        }
    }
}