@use "../config/"as *;



.filter-section {
    @extend %m-b-two-space;

    .filter {
        &-wrap {
            flex-wrap: wrap;
            @extend %flex-sb-c;
        }

        &-body {
            z-index: var(--zindex-filter);
            max-height: calc(100% - var(--header-height) - 7.3rem);
            overflow-y: auto;
            @include position(var(--header-height), 100%, null, null, fixed);
            @extend %p-full;
            @extend %c-blue-bg-10;
            @extend %w-100;
            @extend %h-100;
        }

        &-title {
            width: calc(100% - 3.7rem);
            @extend %text-left;
            @extend %c-white-10;
            @extend %font-20-pb;
        }

        &-toggle {
            width: 3.7rem;
            height: 3.7rem;
            @include border(1, c-white, 10);
            @extend %c-white-bg-2;
            @extend %flex-c-c;
            @extend %ratio-1-1;
            @extend %circle-radius;

            .btn-text {
                @extend %font-zero;
                @extend %m-zero;
                @extend %flex;

                &:after {
                    @include icon('\e80b', 20, 20);
                    @include color(c-white,10);
                }
            }

            &[aria-expanded="true"] {
                ~.filter-body {
                    right: 0;

                    .filter-actions {
                        @extend %flex-sb-c;
                    }
                }

            }
        }

        &-search {
            position: relative;

            .btn {
                &-search {
                    @include position-combo(tr);

                    &[aria-expanded="true"] {
                        @extend %d-none;

                        ~.btn-close {
                            @extend %d-block;
                        }

                    }

                    .btn-text {
                        &:after {
                            @include icon('\e815');
                        }
                    }
                }

                &-text {
                    @extend %font-zero;
                }

                &-close {
                    @include position(null, var(--half-space), var(--half-space));
                    // @extend %d-none;

                    .btn-text {
                        &::after {
                            font-weight: 600;
                            @extend %c-blue-2-10;
                            @include icon('\e815');
                            font-size: 2rem;
                        }
                    }
                }
            }

            input {
                &:focus {
                    ~.btn-close {
                        .btn-text{
                            &::after {
                                @include icon('\e804');
                            }
                        }
                    }
                }
            }
        }

            .toggle-text {
                &:before {
                    @extend %c-white-10;
                }
            }

        &-result-list {
            @extend %flex;
            @extend %m-y-three-space;
            &:empty{
                @extend %m-zero;
            }
        }

        &-result-data {
            @extend %w-100;
        }

        &-result-item {
            @extend %flex;

            img {
                position: relative;
                width: 6rem;
                height: 6rem;
                @include border-radius(50%, hidden);
                @extend %ratio-1-1;
                @extend %c-white-bg-3;
            }

            label {
                background: hsl(var(--hsl-c-blue)/1);
                color: var(--c-white);
                padding-right: 3rem;
                @extend %p-half;
                @extend %badges;

                &+.btn-close {
                    top: 0.25rem;
                    right: 2.5rem;
                    background-color: transparent;

                    .btn-text {
                        &::before {
                            @extend %c-white-4;
                        }
                    }

                }
                span{
                    @extend %c-orange-10;
                    @extend %font-12-pr;
                    @extend %m-l-half;
                }
            }

            .btn-close {
                width: 2rem;
                height: 2rem;
                @include border-radius(50%, visible);
                @include position(0, 2.2rem, null, null, relative);
                @extend %c-white-bg-10;

                .btn-text {
                    @extend %w-100;
                    @extend %h-100;
                    @extend %flex-c-c;
                    @extend %m-zero;
                    @extend %font-zero;
                    @extend %d-block;

                    &::before {
                        @include icon('\e804');
                        @extend %c-black-10;
                    }
                }
            }
        }

        &-actions {
            padding: var(--one-n-half-space) var(--full-space);
            @include position(null, null, 0, 0,fixed);
            @extend %w-100;
            @extend %c-white-bg-10;
            @extend %d-none;

            .btn-text {
                @extend %c-blue-10;
                @extend %font-16-pr;
                @extend %m-zero;
            }

            .btn-link {
                &.active {
                    .btn-text {
                        @extend %font-16-pb;
                    }
                }
            }

        }

        &-label-group {
            @extend %text-left;
            @extend %m-y-full;

            .title {
                @extend %c-white-8;
                @extend %font-14-pr;
            }
        }

    }

    .selected-title {
        @extend %flex-column-c-fs;
    }

    .sub-title {
        @extend %font-12-pr;
        @extend %c-white-8;
    }

    .selected-label {
        @include border(1, c-white, 3, bottom);
        @extend %p-y-half;
        @extend %m-t-quarter;
        @extend %w-100;
        @extend %text-left;
        @extend %flex-sb-n;
        @extend %c-white-10;

        &:after {
            @include icon('\e806');
        }
    }

    .select-box-wrap {
        @extend %d-none;
        @extend %text-left;
        @extend %m-t-quarter;

        .list-item {
            list-style: none;
            @extend %p-y-quarter;
            &:not(:last-child){
                @include border(1, c-white, 3, bottom);
            }
        }
        a,.list-item-text,button {
            @extend %c-white-10;
            @extend %font-14-pr;
        }

        .select {
            &-box-footer {
                padding: var(--full-space) var(--half-space);
                @extend %flex-sb-c;
                .btn-link{
                    @extend %c-white-10;
                    @extend %font-14-pr;
                    &.btn-apply{
                        @extend %c-orange-10;
                        @extend %font-14-pb;
                    }
                }
                

            }

            &-list {
                max-height: 17rem;
                overflow-y: auto;
                @extend %p-half;
                @include custom-scroll();
            }
        }

        .list-item-option {
            position: relative;
            cursor: pointer;
            @include flex-config(flex, row, space-between, center);
            &::after{
                content: '';
                height: 1.6rem;
                width: 1.6rem;
                @include bg(c-white, 1);
                @include position-combo(tr);
                @include border(1, c-white, 6, all);
                @include border-radius(0.3rem);
                // @extend %circle-radius;
                @extend %ratio-1-1;

            }
            .list-item-text{
                display: block;
                width: calc(100% - 2rem);
                &:after {
                    content: "\e805";
                    position: absolute;
                    display: none;
                    font: 1.4rem/1 $font-icon;
                    @include color(c-white, 10);
                    @include position-combo(tr);

                    //
                    width: 1.6rem;
                    height: 1.6rem;
                    // @extend %d-block;
                    @include bg(c-orange, 10);
                     @include border(1, c-orange, 10, all);
                     @include border-radius(0.3rem);
            }
        }
        .list-item-input{
            opacity: 0;
                cursor: pointer;
                z-index: var(--zindex-input);
                width: 1.6rem;
                height: 1.6rem;
                @include position-combo(tr);
                &:checked {
                    +.list-item-text {
                    //   @include bg(c-orange, 10);
                    //   @include border(1, c-orange, 10, all);
          
                      &::after {
                        @extend %flex-c-c;
                      }
                    }
                  }
        }
    }

    }

    .label-item {
        background: hsl(var(--hsl-c-white)/0.2);
        @extend %m-x-half;
        @extend %badges;

        &.active {
            @extend %c-white-bg-10;

            .btn-text {
                @extend %c-blue-10;
            }
        }

        .btn-text {
            margin: 0;
            @extend %font-14-pr;
        }
    }

    .waf-select-box {
        margin-bottom: var(--two-space);

        &.active {
            &>.select-box-wrap {
                @extend %d-block;
            }
            .selected-label{
                &::after{
                   content: '\e802';
                }
            }
        }
    }
    .secondary.tabs{
        flex-wrap: wrap;
        gap:var(--half-space);
        .tab {
            &-name {
                border-color: var(--c-white);
                @extend %c-white-bg-2;
                &.active{
                    @extend %c-white-bg-10;
                    @extend %c-blue-10;
                }
            }
    
            &-text {
                @extend %c-white-10;
            }
        }
    }
    
    .no-data-wrap{
        @extend %p-zero;
        @extend %d-none;
    }
}

.event-details{
    @include white-bg();
    .filter-section{
        .filter{
            &-search{
                @extend %d-none;
            }
            &-toggle{
                @extend %d-none;        
            }
            &-title{
                @extend %d-none;
            }
            &-body{
               
               position: relative;
               max-height: unset;
               z-index: unset;
               top: unset;
               right: unset;
               overflow: hidden;
               width: 100%;
               @extend %c-white-bg-10;
               @extend %p-x-zero;

            }   
        }
    
    }

}

.iPhone {
    .filter-section {
        .filter-body {
            max-height: calc(100% - var(--header-height));
        }
    }
}

@media screen and (min-width:$tablet-min-width) {
    .filter-section {
        padding-inline: 0;
        .filter {

            &-toggle,
            &-title {
                display: none;
            }

            &-body {
                position: relative;
                background-color: transparent;
                width: 100%;
                flex-wrap: wrap;
                right: 0;
                top: unset;
                overflow: unset;
                padding: 0;
                @include flex-config(flex);
            }

            &-group {
                flex-basis: 30%;
                @include flex-config(flex);

                .waf-select-box {
                    position: relative;
                    min-width: 15rem;
                    margin-right: var(--full-space);
                }
            }

            &-label-group{
                flex-basis: 58%;
                flex-grow: 1;
            }
            &-hide-data{
                width: 20%;
                @include position(62%,0);
                .toggle-text{
                    &::before{
                        content: unset;
                    }
                }
            }

            &-search {
                width: 20%;
                z-index: var(--zindex-filter-search);
                @include position(20%,0);
                @include flex-config(flex, null, null, center);

                input {
                    border-bottom: none;
                }

            }

            &-actions {
                display: none;
            }
        }

        .search-wrapper {
            position: relative;
            height: 4rem;
            @include border-radius(10vmax);
            @include bg(c-white, 2);
        }

        .btn-search {
            top: 4rem;
        }

        .selected-label {
            @include color(c-white);
            @include truncate-vertical(1,14);
            // display: flex;//override the truncate display -webkit-box
            height: 3.5rem;//override the truncate height
            padding-inline: var(--full-space) var(--two-space);
            position: relative;
            &::after {
                font-size: 3rem;
                @include position(var(--half-space),0,null,null);
                @include color(c-white);
            }
        }
        .secondary.tabs{
            width: 85%;
        }
        .select-box-wrap{
            min-width: 29rem;
            z-index: var(--zindex-dropdown);
            @include position(null,0,null,0);
        }
    }
    .event-details{
        .filter-section {
            .filter-body {
                overflow: unset;
            }
        }
    }
}