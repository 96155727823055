@use "../config/" as *;
.homepage {
    .waf-search {
        max-height: calc(var(--window-inner-height) - (var(--header-height) + var(--eventstrip)));
        &.active {
            top: calc(var(--header-height) + var(--eventstrip));
            right: 0;
        }
    }
    &.no-live-event .waf-search {
        max-height: calc(var(--window-inner-height) - var(--header-height));
        &.active {
            top: var(--header-height);
            right: 0;
        }
    }
}
.waf-search {
    max-height: calc(var(--window-inner-height) - var(--header-height));
    padding-bottom: var(--two-space);
    overflow: hidden auto;
    margin: 0;
    z-index: var(--zindex-search);
    pointer-events: none;
    opacity: 0;
    @include transition(300ms);
    @include position(var(--header-height),
        -100vh,
        null,
        0,
        fixed);
    &::after {
        content: "";
        width: 100%;
        height: 100vh;
        display: block;
        z-index: var(--zindex-overlay);
        @include position(0, 0, 0, 0, fixed);
        @extend %c-blue-bg-10;
    }
    &.active {
        top: var(--header-height);
        pointer-events: initial;
        // opacity: 0;//temp
        // display: none;
        opacity: 1;
        @include transition(300ms);
        &.no-scroll {
            overflow: hidden;
        }
    }
    .tab-container-wrap-item:not(:last-child) {
        @extend %m-b-two-space;
    }
    .videos {
        margin-right: var(--full-space-negative);
        .waf-head {
            @extend %p-r-full;
        }
        .article-list {
            gap: var(--full-space);
        }
        .article-item:last-child {
            @extend %m-r-full;
        }
        .article-meta .meta-date {
            padding-left: var(--full-space);
            &::before {
                left: var(--half-space);
            }
        }
        .article-content > a {
            margin-bottom: var(--half-space);
        }
    }
    .waf-listing.horizontal-list {
        .img-box {
            aspect-ratio: 2/3;
            padding-bottom: 0;
        }
        img {
            image-rendering: auto;
        }
    }
    .horizontal-list,
    .institutional-list {
        .article {
            &-list {
                gap: 1.2rem;
            }
            &-item {
                margin-block: 0;
            }
        }
    }
    .waf-eventstrip.waf-event {
        padding: 0;
        margin-block: 0;
        margin-right: var(--full-space-negative);
        .swiper-button {
            @extend %d-none;
        }
        .card-list {
            overflow: auto;
            @include flex-config(flex, row);
            @include card-count(1.15, var(--full-space), nowrap);
        }
        .event-item:last-child {
            @extend %p-r-full;
        }
        .waf-head {
            @extend %p-r-full;
            .head-tab {
                position: unset;
                transform: unset;
            }
        }
    }
    &-item {
        @extend %m-y-two-space;
    }
    .waf-head {
        @extend %m-b-full;
        .title {
            @extend %font-14-pb;
        }
        .head-tab a {
            font: 400 1.4rem/1 $font-pr;
        }
    }
    > .layout-wrapper {
        > .waf-head {
            max-width: var(--container-max-width);
            margin: auto;
            max-height: calc(var(--search-height) + var(--tabs-height));
        }
        > .waf-body {
            .waf-component {
                @extend %m-t-zero;
                @extend %m-b-full;
            }
            .layout-wrapper {
                padding-bottom: 0;
                &::after,
                &::before {
                    content: unset;
                }
            }
            .featured-list {
                background: transparent;
            }
            .title {
                @extend %c-white-10;
            }
        }
    }
    &.tabs-hidden {
        > .layout-wrapper {
            > .waf-body {
                height: calc(var(--window-inner-height) - var(--header-height) - var(--eventstrip) - var(--search-height) - var(--two-space));
            }
        }
    }
    .head-wrap {
        flex-wrap: wrap;
    }
    @extend %w-100;
    @extend %c-blue-bg-10;
    @extend %y-auto;
    .search-result-title {
        @extend %d-none;
    }
    .btn-search {
        @extend %d-none;
    }
    .filter-section {
        @extend %d-none;
    }
    .btn-close {
        height: 3.7rem;
        @extend %flex-c-c;
        @extend .btn-outline;
        &::before {
            width: 1.5rem;
            @include icon(\e800, 22, 10);
            @extend %flex-c-c;
        }
    }
    .search-result {
        &-header {
            @extend %w-100;
            @extend %flex-c-c;
            > .btn {
                @extend %d-none;
            }
        }
        &-input {
            max-width: 70rem;
            @extend %m-auto;
            @extend %w-100;
            @extend %relative;
            .form-control {
                @extend %rounded-radius;
                @extend %c-white-bg-10;
                @extend %c-blue-8;
            }
            .btn-close {
                font-size: 0;
                border: 0;
                @include position-combo(y-center, var(--half-space));
                &::before {
                    content: "\e815";
                    @extend %c-blue-8;
                }
                &.active {
                    &::before {
                        content: "\e804";
                    }
                }
            }
        }
    }
    .no-data-wrap {
        max-width: 47rem;
        @include flex-config(null, column);
        @include border(1, c-white, 2);
        @extend %m-y-two-space;
        @extend %m-x-auto;
        @extend %c-white-bg-1;
        @extend %full-radius;
        .title {
            width: 100%;
            background-color: transparent;
            border: 0;
            @extend %font-20-pr;
        }
        .no-data-option {
            border-top: 0.1rem solid var(--c-white);
            @include border(1, c-white, 2, top);
            @extend %text-center;
            @extend %w-100;
            @extend %m-t-full;
            @extend %p-t-full;
        }
        .search-txt {
            @extend %font-16-pr;
            @extend %c-white-10;
            @extend %m-b-half;
        }
        .txt1 {
            font-family: $font-pb;
        }
        a {
            text-decoration: underline;
        }
    }
    .tab {
        height: var(--tabs-height);
        &-text {
            @include font(14);
            @extend %c-white-10;
        }
        &-section {
            @extend %m-x-auto;
            @extend %p-x-zero;
            @extend %c-white-10;
            @extend %x-auto;
        }
        &-name {
            @extend %c-white-10;
            &.active {
                @include border(2, c-orange, 10, bottom);
            }
        }
    }
    .result {
        .card {
            &-item {
                border-block: 0.1rem solid hsl(var(--hsl-c-white)/0.2);
                @extend %p-y-full;
                &.active {
                    border-color: hsl(var(--hsl-c-white)/0.4);
                    a {
                        font-family: $font-pb;
                    }
                }
            }
        }
        a {
            position: relative;
            @extend %c-white-10;
            @extend %font-16-pr;
            @extend %flex;
            &:before {
                content: '';
                width: 2.4rem;
                position: absolute;
                right: 0;
                @extend %c-white-bg-2;
                @extend %d-block;
                @extend %ratio-1-1;
                @extend %circle-radius;
            }
            &::after {
                width: 2.4rem;
                position: absolute;
                right: 0;
                font-size: 2rem;
                @extend %ratio-1-1;
                @extend %flex-c-c;
                @include icon('\e801');
            }
        }
    }
    .athletes {
        .card {
            &-list {
                flex-direction: column;
                gap: var(--full-space);
            }
            &-item {
                padding: var(--half-space) var(--full-space);
                @extend %flex-c-c;
                @extend %c-white-bg-1;
                @include border-radius(var(--half-radius));
            }
            &-content {
                width: calc(100% - 4rem);
                @extend %m-l-full;
                @extend %flex-column;
                a {
                    @extend %font-zero;
                    @include position-combo(inset);
                }
            }
            &-title {
                @extend %m-zero;
                @extend %c-white-10;
                @extend %font-14-pb;
            }
            &-meta {
                @extend %flex-fs-c;
            }
        }
        .meta {
            @extend %c-white-6;
        }
        .country {
            @extend %flex;
            @extend %p-r-half;
            // &::after {
            //     content: '|';
            //     @extend %m-l-half;
            // }
            img {
                width: 2rem;
                height: 2rem;
                object-fit: contain;
                @extend %m-r-half;
                @extend %rounded-radius;
            }
        }
    }
    .swiper {
        text-align: center;
        & > * {
            text-align: left;
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-search {
        .no-data-wrap {
            padding-inline: var(--two-space);
        }
        .tab {
            &-name {
                margin-inline: var(--full-space);
            }
        }
        .search-result {
            &-header {
                position: relative;
                > .btn-close {
                    display: flex;
                    @include position(0, null, null, 0);
                }
            }
        }
        .tab-container-wrap-item:not(:last-child) {
            margin-bottom: var(--three-space);
        }
        .waf-listing.horizontal-list .img-box {
            aspect-ratio: 3/2;
        }
        .athletes {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
            .card {
                &-content {
                    a {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1/1;
                        right: var(--half-space);
                        left: auto;
                        display: block;
                        bottom: unset;
                        @include position-combo(y-center);
                        @include color(c-white);
                        &::after {
                            width: 2.4rem;
                            aspect-ratio: 1/1;
                            right: 0;
                            @include border-radius(50%);
                            @include bg(c-white, 2);
                            @include position-combo(y-center);
                            @include flex-config(flex, null, center, center);
                            @include icon('\e801');
                        }
                    }
                }
            }
        }
        .waf-eventstrip.waf-event {
            max-width: unset;
            margin-inline: 0;
            .waf-head,
            .event-item:last-child {
                padding-right: 0;
            }
            .card {
                &-list {
                    flex-wrap: wrap;
                    @include card-count(3, var(--full-space));
                }
            }
        }
        .videos {
            margin-right: 0;
            .waf-head {
                padding-right: 0;
            }
            .swiper {
                &::before,
                &::after {
                    content: unset;
                }
                &-button-prev,
                &-button-next {
                    display: none
                }
            }
            .article {
                &-list {
                    @include card-count(3, var(--one-n-half-space), wrap);
                }
                &-item:last-child {
                    margin-right: 0;
                }
            }
        }
        .horizontal-list,
        .institutional-list {
            .article-list {
                gap: var(--full-space);
            }
        }
        .all {
            .waf-eventstrip.waf-event {
                .card-list {
                    gap: 0;
                    overflow: unset;
                    flex-wrap: unset;
                }
                .swiper-button-next,
                .swiper-button-prev {
                    display: flex;
                }
            }
            .videos {
                .swiper {
                    &::before,
                    &:after {
                        content: "";
                        width: 20rem;
                        height: 100%;
                        z-index: var(--zindex-featured-list-gradient);
                        pointer-events: none;
                        @include linear-gradient(-268.67deg, hsl(var(--hsl-c-blue)/0.6) 56.77%, hsl(var(--hsl-c-blue)/0) 100%);
                    }
                    &::before {
                        @include position(0, null, null, calc(-1 * var(--container-white-space)));
                    }
                    &::after {
                        @include position(0, calc(-1 * var(--container-white-space)));
                    }
                }
                .swiper-button-next,
                .swiper-button-prev {
                    display: flex;
                    top: 50%;
                }
                .article-list {
                    flex-wrap: unset;
                    gap: 0;
                }
            }
            .swiper-button {
                &-prev,
                &-next {
                    top: 30%;
                    &::after {
                        font-size: 4rem;
                        line-height: 1;
                        @include color(c-white);
                    }
                }
            }
        }
        .waf-head .head-tab a {
            font: 700 1.6rem/1 $font-pb;
            gap: var(--half-space);
            @include flex-config(flex, null, null, center);
            &::after {
                @include icon('\e801');
                @include color(c-orange);
                @include font(24);
            }
        }
        .preview-swiper .swiper-button {
            &-prev {
                left: 0;
            }
            &-next {
                right: 0;
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-search {
        .videos .article-list {
            @include card-count(4, var(--one-n-half-space), wrap);
        }
        .all {
            .videos .article-list {
                flex-wrap: unset;
                gap: 0;
            }
        }
    }
}