@use "../config" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}

body,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
strike,
strong,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 1.4rem;
}

figure {
  margin: 0;
}

small,
label {
  font-size: 1.2rem;
}

big {
  font-size: 1.6rem;
}

sub,
sup {
  font-size: 1rem;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: var(--half-space) 0;
}

body {
  margin: 0;
  font-family: $font-pr, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  line-height: $default-line-height;
  @extend %c-white-bg-10;
}

a {
  @extend %c-orange-10;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

ul,
ol,
dl,
dd {
  list-style: disc;
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  @extend %c-black-10;

  &:focus {
    outline: none;
  }
}

input {
  &:focus-within {
    outline: none;
  }
}

@media screen and (min-width: $tablet-min-width) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.6rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.8rem;
  }

  h6 {
    font-size: 1.6rem;
  }
}
