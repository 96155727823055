@use "../config" as *;
.waf-ad {
    display: block;
    height: max-content;
    @extend %w-100;
    @extend %m-y-full;
    &-wrapper {
        font-size: 0.1rem;
        isolation: isolate;
        @include shimmer-ads();
        @extend %m-x-auto;
        @extend %text-center;
        @extend %flex-c-c;
        &::after {
            z-index: -1;
        }
    }
    &-leader,
    &-rectangle {
        .waf-ad-wrapper {
            width: 32rem;
            height: 5rem;
        }
    }
    &-sidebar {
        .waf-ad-wrapper {
            width: 16rem;
            height: 60rem;
        }
    }
    &-square {
        .waf-ad-wrapper {
            width: 25rem;
            height: 25rem;
        }
    }
    &-vrec {
        .waf-ad-wrapper {
            width: 30rem;
            height: 25rem;
        }
    }
    &-content {
        .waf-ad-wrapper {
            width: 30rem;
            height: 60rem;
        }
    }
    &.overlay {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: var(--zindexoverlayads);
        @extend %c-black-bg-6;
        @extend %m-zero;
        &.overlay-hide {
            @extend %d-none;
        }
        .btn-close {
            @include position(var(--full-space), var(--full-space));
            @extend %c-white-10;
            @extend %font-zero;
            &::before {
                content: "\e804";
                font: 1.8rem/1 $font-icon;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-ad {
        &-leader {
            .waf-ad-wrapper {
                width: 72.8rem;
                height: 9rem;
            }
        }
        &-rectangle {
            .waf-ad-wrapper {
                width: 46.8rem;
                height: 6rem;
            }
        }
    }
}
// @media screen and (min-width: $desktop-min-width) {}