@use "../config/" as *;

.table{
    border-collapse: collapse;
    @include border(1,c-black,1);

    thead,&-head{
        height: 4rem;
        @extend %c-blue-bg-10;

    }
    tbody,&-body{
        tr,td,.table-data{
            height: 6rem;
        }
    }
    &-row{
        position: relative;
        @include border(1,c-blue,1,bottom);
        @extend %flex-fs-c;
        @extend %w-100;
        @extend %h-100;
    }
    &-data{
        flex-shrink: 1;
        @extend %w-100;
        @extend %text-center;
        @extend %flex-fs-c;
        @extend %p-x-half;
    }
    &-right{
        overflow-x: auto;
    }
    .text{
        @extend %capitalize;
        @extend %c-blue-10;
        @extend %font-12-pr;
    }
    &-action{
        @extend %text-center;
        @extend %m-t-two-space;
        .btn-fill{
            @extend %c-blue-bg-10;
            @extend %c-white-10;
        }
    }
    &-title{
        @extend %font-18-pb;
        @extend %m-b-full;
    }
}

@media (min-width:$tablet-min-width) {
    .table{
        &-title{
            @include font(20);
        }
    }
    
}